import React from 'react';
import Discount from '../../Components/home/Discount/Discount';
import Slider from '../../Components/home/Slider/Slider';
import Products from '../../Components/home/Products/Products';
import Poster from '../../Components/home/Poster/Poster';
import Preferences from '../../Components/home/Preferences/Preferences';
import Carousel from '../../Components/home/Carousel/Carousel'
import BrandSlider from '../../Components/home/BrandSlider/BrandSlider';
import About from '../../Components/home/About/About';



const Home = () => {
  return (
    <>
     {/* <Discount/>
     <BrandSlider/>
    <Products/>
    <Poster/>
  <About/> */}
  <img src='./homepage.png'></img>

    
    </>
  )
}

export default Home;
