import React from 'react'
import { about, product1, product2, product3, product4 } from '../../assets/images';

const About = () => {
  return (
    <>
      <div className='bg-[#020202]'>
      <div className="max-w-container mx-auto py-16 ">
    <p className="text-center text-3xl font-medium mb-6 text-[#DFB66F]">Biz kimik?</p>
   <div className='flex justify-between'>
    <div>
   <img src={about}></img>
    </div>
    <div className='flex justify-center items-center ml-10'>
        <p className='text-[white] w-2/3'>KCollection, zərif və yenilikçi parfüm kolleksiyalarımızla sizi öz dünyamıza dəvət edir. 2010-cu ildən bəri, biz lüks və mükəmməl qoxular yaratmağa özümüzü həsr etmişik. Hər bir ətirimiz, unikal tərkib hissələri və yüksək keyfiyyətli komponentlərlə hazırlanır, məqsədimiz isə müştərilərimizə həm estetik, həm də emosional bir təcrübə təqdim etməkdir.</p>
    </div>
   </div>
  </div>
  </div>
    </>
  )
}

export default About
