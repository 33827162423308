import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "../src/Pages/Home/Home";

import { ToastContainer } from "react-toastify";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import Basket from "./Pages/Basket/Basket";
import Products from "./Pages/Products/Products";
import Sofa from "./Pages/ProductDetail/ProductDetail";
import ProductDetail from "./Pages/ProductDetail/ProductDetail";



const Layout = () => {
  return (
    <div>
       <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      /> 
      {/* <Header /> */}
      <ScrollRestoration />
      <Outlet />
      {/* <Footer /> */}
    </div>
  );
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        {/* ==================== Header Navlink Start here =================== */}
        <Route path="/" element={ <Home />}></Route>
        <Route path="/about" element={ <About/>}></Route>
        <Route path="/products" element={ <Products/>}></Route>
        <Route path="/contact" element={ <Contact/>}></Route>
        <Route path="/basket" element={ <Basket/>}></Route>
        <Route path="/product-detail/:id" element={ <ProductDetail/>}></Route>
      </Route>
    </Route>
  )
);

function App() {

  return (
    <div className="font-bodyFont">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;




