import React from "react";
import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";
import {
  deleteItem,
  drecreaseQuantity,
  increaseQuantity,
} from "../../Components/redux/orebiSlice";

const ItemCard = ({ item, isSelected, onSelect }) => {
  const dispatch = useDispatch();
  return (
    <div className="w-full flex items-center justify-between border-b py-2 mb-4">
  <div className="flex items-center gap-4 ml-4">
    <input
      type="checkbox"
      checked={isSelected}
      onChange={onSelect}
      className="mr-2"
    />
    <img className="w-20 h-20 object-cover" alt="productImage" src={item.image} />
    <div>
      <h1 className="font-titleFont text-lg">{item.name}</h1>
      <p className="font-titleFont  text-sm">Ölçü: {item.size} • Say: {item.quantity} • Gender: {item.gender}</p>
      <p className="text-primeColor font-semibold">{item.quantity * item.price} AZN</p>
    </div>
  </div>
  <div className="flex items-center gap-6 pr-4">
    <div className=" items-center gap-4">
   <div>
   <span  onClick={() => dispatch(deleteItem({ id: item.id, size: item.size, color: item.color }))}
      className="text-primeColor hover:text-red-500 cursor-pointer flex justify-end mb-5 "><i class="fa-solid fa-trash-can"></i></span>
   </div>
     <div className="flex">
     <span
        onClick={() => dispatch(drecreaseQuantity({ id: item.id, size: item.size, color: item.color }))}
        className="w-8 h-8 flex items-center justify-center text-lg hover:bg-gray-300 cursor-pointer mr-2 border-[#F1F1F1] border-2"
      >
        -
      </span>
      <p>{item.quantity}</p>
      <span
        onClick={() => dispatch(increaseQuantity({ id: item.id, size: item.size, color: item.color }))}
        className="w-8 h-8 flex items-center justify-center text-lg hover:bg-gray-300 cursor-pointer ml-2 border-[#F1F1F1] border-2"
      >
        +
      </span>
     </div>
    </div>

  </div>
</div>

  );
};

export default ItemCard;

