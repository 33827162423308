import React, { useEffect, useState } from "react";
import { sofa } from "../../Components/data/Data";
import Others from "./Others";
import { useDispatch } from "react-redux";
import { addToCart } from '../../Components/redux/orebiSlice';
import Modal from "./Modal"; // Import the Modal component
import { parfum1 } from "../../assets/images";

export default function ProductDetail() {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [selectedColor, setSelectedColor] = useState(""); 
  const [selectedQuantity, setSelectedQuantity] = useState(1); 
  const [selectedSize, setSelectedSize] = useState("50ml"); 
  const [selectedId, setSelectedId] = useState(1); 
  const [selectedDimension, setSelectedDimensions] = useState("60x90"); 
  const [selectedGender, setSelectedGender] = useState('Unisex'); 
  const [selectedPrice, setSelectedPrice] = useState(100); 
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filter, setFilter] = useState("single");

  useEffect(() => {
    if (sofa.length > 0) {
      setSelectedProduct(sofa[0]);
      setSelectedColor(sofa[0].name);
    }
  }, []);

  const handleColorChange = (color) => {
    setSelectedColor(color);
    const product = sofa.find(item => item.name === color);
    setSelectedProduct(product);
  };

    const changeSizeToL = () => {
    setSelectedSize("50ml");
    setSelectedDimensions("60x90")
    setSelectedPrice(100)
    setSelectedId(2)
  }
  const changeSizeXL = () => {
    setSelectedSize("100ml");
    setSelectedDimensions("80X110")
    setSelectedPrice(120)
    setSelectedId(1)
  }
  const changeSizeXXL = () => {
    setSelectedSize("150ml");
    setSelectedDimensions("95X135")
    setSelectedPrice(150)
    setSelectedId(1)
  }
  const changeSizeXXXL = () => {
    setSelectedSize("200ml");
    setSelectedDimensions("110X150")
    setSelectedPrice(200)
    setSelectedId(1)
  }


  const decreaseQuantity = () => {
    if(selectedQuantity > 1) {
      let quantity2 = selectedQuantity - 1;
      setSelectedQuantity(quantity2);
    }
  };

  const increaseQuantity = () => {
    let quantity2 = selectedQuantity + 1;
    setSelectedQuantity(quantity2);
  };

  const filteredColors = filter === "single" 
    ? sofa.filter(item => !item.name.includes("/")) 
    : sofa.filter(item => item.name.includes("/"));

  return (
   <>
   <div className="bg-black">
    <div className="w-[80%] mx-auto text-[white]">
      <p className="pt-5 pb-5 text-[#969696]"><a href="/">Ana səhifə</a> &gt; <span className="text-[white] font-medium">Sofa pouffe</span></p>
      <div className="flex justify-between">
        <div className="w-1/5 mr-10">
          {selectedProduct && (
            <div className="mb-4">
            <img src={parfum1} alt={selectedProduct.name} className="w-5/6 h-auto" />
          </div>
          )}
        </div>
        <div className="w-4/5">
          <h4 className="text-[25px] font-medium mb-4">Sofa pouffe</h4>
          <h4 className="text-xl font-semibold mb-4 text-[#DFB66F]">{selectedGender}</h4>
          <h4 className="text-xl font-semibold mb-4 text-[#DFB66F]">{selectedPrice} AZN</h4>
          <p className="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In varius elit lacus, nec porttitor odio interdum id. Proin tempor lorem enim, commodo aliquam turpis hendrerit non.</p>
          <h4 className="text-[16px] font-medium mb-4">Ölçülər</h4>

          <div>
      <button
        onClick={changeSizeToL}
        className={`${
          selectedSize === "50ml" ? "bg-[#DFB66F]" : "bg-[#F1F1F1] text-[black]" 
        } text-white rounded-md px-4 py-2 mr-2`}
      >
     50ml
      </button>
      <button
        onClick={changeSizeXL}
        className={`${
          selectedSize === "100ml" ? "bg-[#DFB66F]" : "bg-[#F1F1F1] text-[black]" 
        } text-white rounded-md px-4 py-2 mr-2`}
      >
        100ml
      </button>
      <button
        onClick={changeSizeXXL}
        className={`${
          selectedSize === "150ml" ? "bg-[#DFB66F]" : "bg-[#F1F1F1] text-[black]" 
        } text-white rounded-md px-4 py-2 mr-2`}
      >
        150ml
      </button>
      <button
        onClick={changeSizeXXXL}
        className={`${
          selectedSize === "200ml" ? "bg-[#DFB66F]" : "bg-[#F1F1F1] text-[black]" 
        } text-white rounded-md px-4 py-2`}
      >
        200ml
      </button>
    </div>
          <h4 className="text-[16px] font-medium mb-4 mt-4">Məhsul sayı</h4>
          <div className="flex">
          <button className="h-[36px] w-[36px] border-[#F1F1F1] border-2" onClick={decreaseQuantity}>-</button>
          <p className="p-2">{selectedQuantity}</p>
            <button className="h-[36px] w-[36px] border-[#F1F1F1] border-2" onClick={increaseQuantity}>+</button>
          </div>
          <div className="flex">
            <button 
              onClick={() => setIsModalOpen(true)} // Open modal on button click
              className="mt-4 px-4 py-2 bg-[#DFB66F] text-white rounded-full w-[170px]"
            >
              İndi al
            </button>
            <button 
              onClick={() =>
                dispatch(
                  addToCart({
                    id: selectedId,
                    name: 'Sofa pouffe',
                    quantity: selectedQuantity,
                    image: parfum1,
                    color: selectedColor,
                    price: selectedPrice,
                    size: selectedSize,
                    gender: selectedGender
                  })
                )
              } 
              className="mt-4 px-4 py-2 border-[#DFB66F] rounded-full border-2 w-[170px] ml-4"
            >
              Səbətə at
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
    {/* Modal */}
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <form>
        <h2 className="text-xl font-semibold mb-4">Alış Formu</h2>
        <div className="mb-4">
          <label className="block text-gray-700">Ad:</label>
          <input type="text" className="border rounded-md p-2 w-full" placeholder="Adınızı daxil edin" />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Email:</label>
          <input type="email" className="border rounded-md p-2 w-full" placeholder="Emailinizi daxil edin" />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Telefon:</label>
          <input type="text" className="border rounded-md p-2 w-full" placeholder="Telefon nömrənizi daxil edin" />
        </div>
        <div className="flex justify-end">
          <button type="submit" className="px-4 py-2 bg-[#5A639C] text-white rounded-md">Təsdiqlə</button>
        </div>
      </form>
    </Modal>

    <Others />
    </>
  );
}